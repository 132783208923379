@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@400;700&family=Poppins:ital,wght@0,400;0,500;0,700;1,500&family=Quicksand:wght@500&family=Roboto+Slab:wght@100;400;700&display=swap');
:root{
    --navBorderColor:#0000001e;
    --knockBgColor:#16d6ff;
    --textGreenColor:#00d28f;
    --taglineTextColor:#15CEFF;
    --taglineFullstopColor:#41D692;
    --landingStaticTextColor:#3E3E3E;
    --landingDynamicTextColor:#41D692;
    --headlineTextColor:#15CEFF;
    --bodyTextColor:#3E3E3E;
    --subheadlineTextColor:#15CEFF;
    --footerTextColor:#3E3E3E;
    --blue:#15CEFF;
    --max-width:1286px;
    --footerBgColor:#f5f5f5;
    --BoxShadow:0 0 6px #51515120;
    --regular:400;
    --medium:500;
    --semiBold:600;
    --light:300;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


  /* Nav bar start */
  #logo-link-container{
    display: contents;
  }
  #nav-bar-container{
    height: 68px;
    position: fixed;
    z-index: 2;
  }
  .nav-bar{
    border: none;
    margin: 0;
    padding: 10px 16px 10px 16px;
    max-width:var(--max-width);
}

.nav-bar{
    width: 100%;
    height: 68px;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 1;
    padding-top: .2rem;
    box-shadow: 0 3px 5px #00000016;
    padding: 6px 15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  /* Nav bar end */
#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 2;
    cursor: pointer;
  }
/* Landing Page start */


.landing-page-container{
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 68px);
    margin-top: 68px;
}
#animation{
    width: 669px;

}

.main-container{
    /* max-width: 1130px; */
    height: calc(100% - 46px);
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
.landing-services-container{
    margin-top: 2rem;
}
.mob-landing-static-txt{
    text-align: center;
    font-family: 'Poppins',sans-serif;
    font-size: 20px;
    font-weight: var(--medium);
    color: var(--landingStaticTextColor);
}
.tab-desk-body-txt{
    font-family: 'Poppins',sans-serif;
    font-size: 20px;
    color: var(--bodyTextColor);
    
letter-spacing: 2px;}

#mob-tagline-txt{
    display: none;
    
}

#tab-desk-tagline-txt .tagline-txt{
    font-size: 45px;
    color: var(--taglineTextColor);
    font-family: 'Roboto Slab', sans-serif;
    font-weight: bold;
    
}
#tab-desk-landing-static-txt{
    font-size: 20px;
    font-weight: var(--medium);
    font-family: 'Poppins',sans-serif;
    color: var(--bodyTextColor);
letter-spacing: 2px;}
.mob-landing-static-txt{
    display: none;
}
.mob-landing-static-txt{
    display: none;
}
#mob-body-txt{
    display: none;
}
/* footer start */
.popup-social-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 30px;

}
/* footer end */
/* Nav bar styles start */
nav{
    display: flex;
    justify-content: space-between;

}
.hamburger-container{
    display: none;
    position: relative;
    width: 41px;
    top: 23px;
}

#hamburger{
   position: absolute;
   background-color: white;
   padding-top: 3px;
   padding-bottom: 3px;
}

.fade-out{
    animation: fadeout .9s;
    
}
.fade-in{
    animation: fadein .9s;
    
}

#popup-close-btn{
    position: absolute;
    display: block;
    cursor: pointer;
    background-color: transparent;
    /* animation: fadein .9s; */
    /* transition: all 0.5s;
    -webkit-transition: all 0.5s; */
    background-color: white;
    opacity: 0;
}
/* popup namc bar start*/
.popup-navbar-content{
    position: fixed;
    width: 70%;
    /* transition: .9s; */
    z-index: 2;
    right:-70%;
    top: 70px;
    height: 93.6%;
    backdrop-filter: blur(21px);
    -webkit-backdrop-filter: blur(21px);
    box-shadow: 0px 5px 6px #00000025;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
}

#desk-knock {
    margin-top: auto;
    margin-bottom: auto;
    background-color: var(--knockBgColor);
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    width: 142px !important;
    font-family: 'Quicksand',sans-serif;
    border-radius: 40px;
    text-align: end;
}
#desk-knock:hover{
    box-shadow:0px 0px 5px #00000040;
}
#mob-tab-knock-popup{
    width: 385px;
    height: 478px;
    display: none;
    position: fixed;
    z-index: 5;
    background-color: white;
    padding: 35px 58px;
    border-radius: 1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
    top: 25%;
}
#mob-tab-knock-popup p{
    font-family: 'Poppins';
    font-size: 16px;
    color: var(--bodyTextColor);
    line-height: 25px;
    margin: 1rem 0;
}
#mob-tab-knock-popup a{
    width: 246px;
    border-radius:30px;
    border: none;
    background-color: var(--blue);
    color: white;
    padding: .8rem 2rem;
    display: inline-block;
    text-decoration: none;
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: var(--semiBold);
}

#mob-tab-knock-popup div{
    text-align: center;
}
#mob-tab-knock-popup>img{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.knock-popup-container{
    display: none;
    width: 771px;
    height: 478px;
    position: fixed;
    z-index: 5;
    right: 20%;
    top: 20%;
    border-radius: 1rem;
    background-color: white;

}
.knock-popup-container>div{
  padding-bottom: 1rem;
  
}
.knock-popup-left-side>p{
    /* text-align: center; */
    font-size: 16px;
    font-family: 'Poppins',sans-serif;
    font-weight: var(--regular);
    color: var(--bodyTextColor);
    margin-top: 20px;
    margin-bottom: 21px;
    line-height: 27px;

}
.knock-popup-left-side{
   width: 50%;
   text-align: center;
   border-radius: 1rem;
   padding: 35px 58px;
   
 }

 .knock-popup-right-side h3{
    color: white;
    font-family: 'Poppins',sans-serif;
    font-size: 20px;
    font-weight: var(--medium);
    margin-bottom: 30px;
 }
 .knock-popup-right-side p{
    color: white;
    font-family: 'Poppins',sans-serif;
    font-size: 12px;
    font-weight: var(--regular);
    margin-bottom: 13px;
 }
.knock-popup-right-side{
   background-image: url('Popup bg.svg');
   color: white;
   width: 50%;
   text-align: center;
   border-radius: 1rem;
   padding: 35px 20px;
}
.knock-popup-right-side>form>div{
    width: 257px;
    height: 27px;
}
.knock-popup-right-side>form>div>input{
    border-radius: .3rem;
    border: none;
    height: 100%;
    padding-left: .5rem;
    width: 100%;
    font-family: 'Poppins',sans-serif;
    font-size: 12px;
    font-weight: 300;
}
.knock-popup-right-side>form>div>input:focus,
.knock-popup-right-side>form>div>textarea:focus{
outline: none !important;
border: 2px solid var(--landingDynamicTextColor);
}
.knock-popup-right-side>form textarea{
    border-radius: .3rem;
    border: none;
    width: 100%;
    height: 101px;
    padding-top: .3rem;
    padding-left: .5rem;
    font-family: 'Poppins',sans-serif;
    font-size: 12px;
    font-weight: 300;
    resize: none;
}
.knock-popup-right-side>form input[type='submit']{
    border-radius:30px;
    border: none;
    background-color:white;
    color: var(--blue);
    padding: 19px 91px;
    border: none;
    height: 58px;
    font-family: 'Quicksand',sans-serif;
    font-size: 16px;
    font-weight: var(--semiBold);

}
#knock-contact-form{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
#knock-contact-form>div{
    margin-bottom: 20px;
}
#knock-contact-form div:nth-child(4){
    height: fit-content;
}
.knock-popup-left-side a{
    width: 246px;
    height: 58px;
    border-radius:30px;
    border: none;
    background-color: var(--blue);
    color: white;
    padding:19px 60px;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-weight: var(--semiBold);
    text-decoration: none;
    display:inline-block ;
}


.popup-navbar-content>ul>li{
    list-style-type: none;
    margin-top: 25px;
}
.popup-navbar-content>ul{
   margin-top: 70%;
}
.popup-navbar-content>ul>li>a{
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    color:black;
    }
.logo{
    margin: 0;
    padding: 0;
    display: block;
}
.logo-container{
    display: flex;
}
.logo-1{
    margin-right: 1rem;
}

.nav-bar>ul{
    margin-top: auto;
    margin-bottom: auto;
}
.nav-bar>ul>li{
   list-style-type: none;
   display:inline;
   margin-right:42px;
}
.nav-bar>ul>li>a{
    text-decoration: none;
    color: black;
    font-family: 'Montserrat';
    font-size: 12px; 

}


.knock{
    margin-top: auto;
    margin-bottom: auto;
    background-color:var(--knockBgColor);
    color:white;
    padding: .5rem 2rem;
    border-radius: 1rem;
    width: 142px !important;
    font-family:'Quicksand',sans-serif;
    border-radius: 40px;
    box-shadow: 0px 5px 6px #00000025;
    text-align: end;
    margin-top: 30px;
    
}



.knock-container{
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}
.knock-content::after{
    content: " ";
}

/* Nav bar styles end */

/* Main Container start */

.main-text-wrapper{
    margin-left: auto;
    margin-right: auto;
    width: 588px;
}
.main-text-container{
    width: 100%;
    margin-top: 6.1rem;
}

.main-text-container h1{
    text-align: left;
}
.tagline-fullstop{
    color: var(--taglineFullstopColor);
}
.landing-static-txt{
    color: var(--landingStaticTextColor);
    font-family:'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
}
.landing-dynamic-txt{
    color: var(--landingDynamicTextColor);
    margin-top: 1rem;
    font-family:'Roboto Slab', sans-serif;
    font-size: 35px;
    font-weight: var(--medium);
letter-spacing: 2px;}
.headline-txt{
    color: var(--headlineTextColor);
    text-align: center;
    font-family:'Poppins', sans-serif;
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 2rem;
}

.btn-container{
    display: flex;
    flex-direction: row;
    width: fit-content;
}
.process-container>.headline-txt{
    color: var(--headlineTextColor);
    text-align: center;
    margin-top: 2rem;
    }
.txt-container{
    margin-top: 1rem;
}


.btn-explore{
    background-color:#15CEFF;
    color: white;
    padding: 0.6rem 1rem;
    margin-right: 2rem;
    border: none;
    -webkit-appearance: none;
}
.btn-qutote{
    background-color: white;
    color: var(--blue);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 2px solid #15CEFF;
    -webkit-appearance: none;
}

.btn-container{
    margin-top: 1rem;
    margin-bottom: 3.1rem;
    gap: 2rem;
}
.btn-container>a:hover{
    box-shadow:0px 0px 5px #00000040;
}
.btn-container>a{
    width: 142px;
    height: 41px;
    border-radius: 8px;
    font-size: 16px;
    font-family:'Quicksand', sans-serif;
    text-decoration: none;
    text-align: center;
    line-height: 1.5rem;
}
.landing-services-container{
    text-align: center;
}
/* Main Container end */

/* Social footer start */
.body-txt{
    color: var(--bodyTextColor);
    font-family:'Poppins', sans-serif;
    font-size: 14px;
    font-weight: var(--regular);
}
.sub-headline-txt{
    color: var(--subheadlineTextColor);
    font-family:'Poppins', sans-serif;
    font-size: 16px;
    font-weight:var(--medium);
    margin-bottom: 1.5rem;
}
.body-txt-5{
    color: var(--bodyTextColor);
    font-family:'Poppins', sans-serif;
    font-size: 13px;
    font-weight: var(--regular);
    line-height: 22px;
    display: none;
}
.social-footer-wrapper{
    text-align: center;
}
.social-footer-wrapper>a svg:hover{
    filter: drop-shadow(0px 0px 3px #00000040);
}
.social-link{
    margin: .5rem;
    text-decoration: none;
}
/* Social footer end */

/* Landing Page end*/

                                            /* services start */
.services-main-container{
    width: fit-content;
    text-align: center;
    margin-top: 78px;
    padding-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}
#services-mob-txt{
    display: none;
}

.services-container{
    width: fit-content;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto;
    margin-left: auto;
    margin-right: auto;
}
.services-container p{
  text-align: left;
  color: var(--bodyTextColor);
}
.tech-stack-wrapper{
    display: grid !important;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
    grid-gap: 40px;
    width: fit-content;
}

.services-main-container>p{
    font-family: 'Poppins',sans-serif;
    font-size: 20px;
    color: var(--bodyTextColor);
}

.services-container{
    margin-top: 2rem;
    column-gap: 114px;
    row-gap: 71px;
}

.tech-stack-wrapper>div>img{
    width: 100%;
    height: 100%;
   
}
.tech-stack-wrapper>div{
    border-radius: 30px;
    box-shadow: var(--BoxShadow);
    width: 186px;
    height: 186px;
    padding: 40px;
    text-align: center;
   
}
.tech-stack-wrapper>div span{
    font-family: "Poppins",sans-serif;
    font-size: 13px;
}

.tech-stack-container{
    width: fit-content;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}
.tech-stack-container>h2{
    font-family: 'Poppins',sans-serif;
    font-size: 28px;
    font-weight: var(--medium);
    color: var(--blue);
    text-align: center;
    margin-bottom: 2rem;
}
.tech-stack-container p{
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    color: var(--bodyTextColor);
    margin-top: 2rem;
}
.service>p,.service>div{
    text-align: left;
    margin-top: 1rem;
}
.service{
    width: 434px;
    height: 285px;
    border-radius: 20px;
    box-shadow: var(--BoxShadow);
    padding-left: 41px;
    padding-right: 41px;
    padding-bottom: 44px;
}
.service>h3{
    font-family: 'Quicksand',sans-serif;
    text-align: center;
    font-size: 16px;
    font-weight: var(--medium);
    margin-top: 16px;
}
.services-container p{
    font-family: 'Open Sans';
}
.service p{
    font-size:16px;
}
.service>div p{
    font-weight: bolder;
}


.services-icon>span{
color: var(--blue);
font-family: 'Quicksand', sans-serif;
font-weight: 500;
}

.services-icons-container{
    width: fit-content;
    display: grid;
    margin-top: 2rem;
    grid-template-columns:auto auto auto;
    grid-template-rows: auto auto;
    margin-left: auto;
    margin-right: auto;
    grid-gap: 40px 50px;
}
.services-icon{
    border: 1px solid var(--navBorderColor);
    display: block;
    text-align: center;
    border-radius: 1rem;
    text-decoration: none;
    color: var(--blue);
    width: 208px;
    height: 208px;
    border-radius: 20px;
    padding: 47px 33px;
    border: none;
    box-shadow:var(--BoxShadow);

}
.services-icon>img{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.services-qutote{
    width: 142px;
    height: 41px;
    background-color:var(--blue);
    padding:10px 40px;
    border-radius: .5rem;
    display: inline-block;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-decoration: none;
    color: white;
    font-family: 'Quicksand',sans-serif;
    font-size: 16px;
    
}
/* services end */

                                                                    /* Our Process Start */
.tab-desk-body-txt-5{
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-weight: var(--regular);
    line-height: 25px;
    margin-bottom: 2rem;
   }
.process-container{
    border-radius: 8px;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    max-width: 1130px;
    box-shadow: var(--BoxShadow);
    padding-top: 1rem;
    margin-bottom: 2rem;
    width: 90%;
}

.process-all-img-container{
    width: 100%;
}
.process-imgs-container{
    margin-top:1rem;
    display: flex;
    justify-content: center;

}
.process-imgs-container>div{
    margin: 1rem;

}
.process-imgs-container>div>img{
    width:100%;
}


.process-des-container{
    text-align: center;
    width: 50%;
    height:14rem;
    margin-left: auto;
    margin-right: auto;
    margin-top:1.5rem;
}

  @keyframes fadein {
      from {
          opacity:0;
      }
      to {
          opacity:1;
      }
  }
  @keyframes fadeout {
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }
}
.process-description,.process-img{
    animation: fadein .9s;
}
.process-img{
    animation: fadein .6s;
}
/* Process end */

                                        /* Project start */
#tab-project-svg,
#mob-project-svg,
#desk-project-svg{
    display: none;
}

#Services_button:hover,
#To_get_quote_button:hover{
    cursor: pointer;
    filter: drop-shadow(0px 0px 6px #00000040);
    cursor: pointer;
}


#project-details-container{
    padding: 18px 5px 52px 5px;
    background: linear-gradient(90deg, rgba(0,179,255,1) 0%, rgba(21,206,255,1) 100%);
    margin-bottom: 40px;
    color: white;

}
#project-details-container p{
    text-align: center;
    font-size: 20px;
    font-family: 'Poppins';
    margin-bottom: 52px;

}
#project-count-container{
    display: flex;
    width: fit-content;
    margin: auto;
    gap: 2em;
    margin-bottom: 32px;
 
}
#project-count-container>div span{
    display: block;
    text-align: center;
}
#project-count-container>div span:first-child{
    font-size: 60px;
    font-family: 'Roboto Slab';
    font-weight: var(--medium);
}
#project-count-container>div span:last-child{
    font-size: 14px;
    font-family: 'Poppins';
}
#project-count-container>div{
    display: block;
    text-align: center;
}

#project-btn-container{
 display: flex;
 justify-content:center;
 gap: 93px;
 
}
#project-btn-container>a{
        width: 229px;
        text-decoration: none;
        text-align: center;
   }
#pro-service-btn,
#pro-quoto-btn{
    width: 100%;
    height: 100%;
    padding: 13px 42px;
    font-family: 'Quicksand',sans-serif;
    font-size: 16px;
    font-weight: var(--medium);
    border-radius: 8px;
    color: white;
    
   }
#pro-service-btn:hover,
#pro-quoto-btn:hover{
    box-shadow: 0px 0px 6px #00000040;
}
#pro-quoto-btn{
    border: 2px solid white;
    background-color: transparent;
}
#pro-service-btn{
    background-color: white;
    color: var(--blue);
   }
                                        /* project end */

                                        /* who we are start*/

.whoweare-wrapper,.whatwedo-wrapper,.future-wrapper{
        display: flex;
        width: 100%;
    }
.whoweare-wrapper>div,.whatwedo-wrapper>div,.future-wrapper>div{
        width: 50%;
    }
.whoweare-wrapper div:last-child,.whatwedo-wrapper div:first-child,.future-wrapper div:last-child{
        margin-right: 3rem;
    }
.whoweare-wrapper,.future-wrapper{
        flex-flow: row-reverse;
    }
.whoweare-wrapper>div>img {
        margin: 0;
    }
.project-details-wrapper{
        justify-content: space-around;
    }
.whoweare-main h2{
    text-align: center;
   }
   .whoweare-main{
    margin-left: 2rem;
    margin-right: 2rem;
}
.whoweare-main .whatwedo-container,.future-container{
    margin-top: 2rem;
}
.whoweare-main .whatwedo-container>h2,.future-container>h2{
    margin-bottom: 2rem;
    margin-top: 2rem;
}
.whoweare-container{
    margin-top: 6.3rem;
}
.whoweare-container>h2{
    margin-bottom:2rem;
    margin-top: 2rem;
}
.whoweare-wrapper>div>img,.whatwedo-wrapper>div>img,.future-wrapper>div>img{
    width: 100%;
}
.whoweare-main>h2{
text-align: center;
}
.whoweare-main .body-txt{
    text-align:left;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-weight: var(--regular);
    line-height: 27px;
    }
 .whoweare-container p{
    margin-top: 2rem;
 }
 .future-container p:first-child{
    margin-top: 2rem;
 }
                                         /* who we are end */

   /* careers start */
.careers-main-container{
    margin-top: 100px;
}
.careers-container>h2{
    text-align: center;
    color: var(--blue);
    margin: 2rem 0;
    font-family: 'Poppins',sans-serif;
    letter-spacing: 1px;
    font-size: 28px;
    font-weight: var(--medium);
}
#mob-careers-msg-txt{
    display: none;
 }
.careers-wrapper{
    display: flex;
    max-width: 873px;
    margin-right: auto;
    margin-left: auto;
}
.careers-wrapper p{
    font-family: 'Poppins',sans-serif;
    font-size: 1rem;
    color: var(--bodyTextColor);
}
.careers-wrapper>div:first-child{
    margin-right: 5rem;
}
.careers-container .careers-img{
   width: 364px;
   height: 286px;
}
.careers-msg{
    padding: 89px 88px;
    border-radius:8px;
    box-shadow: var(--BoxShadow);
    margin: 2rem auto;
    width: fit-content;
}
.careers-msg p{
    font-family:'Poppins',sans-serif;
    width: 694px;
    font-size: 1rem;
    color: var(--bodyTextColor);
}
#careers-icon{
    text-align: center;
    font-family: 'Montserrat',sans-serif;
    font-weight: var(--medium)
}
   /* careers end */

   /* contact start */

   /* contact end */

/* Privacy start */
.privacy-main-container h1,h3{
text-align: center;
color: var(--blue);
}
.privacy-main-container h1{
    font-size: 20px;
    font-weight: var(--medium);
    margin-top: 5.8rem;
    margin-bottom: 2rem;
}
.privacy-container{
    width: fit-content;
    margin: 0 auto;
}
.privacy-container>h3{
    font-size: 16px;
    margin-bottom: 2rem;
    font-weight: bold;
    text-align: left;
}

.privacy-main-container{
    font-family: 'Poppins',sans-serif;
    margin: 2rem;
    margin-top: 100px;
}

.privacy-wrapper p{
    font-size: 16px;
    font-weight:var(--regular);
    margin-bottom: 2rem;
}

#privacy-wrapper-sub-txt{
    font-size: 16px;
    font-weight:var(--semiBold);
    color: var(--bodyTextColor);
  
  }
#mob-privacy-txt-container{
    display: none;
}

/* Privacy end */

.nav-bar>ul,.knock-container{
    display: block; 
}


.our-process-mob-view{
    display: none;
}
.our-process-tab-view{
    display: none;
}
.our-process-desk-view{
    display: block;
}

.our-process-desk-view{
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}
.our-process-desk-view>svg{
    margin-left: auto;
    margin-right: auto;
}
.process-container{
    margin-left: auto;
    margin-right: auto;
}
.footer-item1{
    grid-row: 1/3;
    margin-top: 1.5rem;
}

.footer-item1 p{
    margin-top: 1.5rem;
    width: max-content;
}
 .footer-item2,.footer-item3,.footer-item4,.footer-item5{
    margin-top: 1.5rem;
    margin-left: 2rem;
 }


  
.footer-item6 input[type="email"]{
    width: 65%;
    max-width: initial;
    margin-left: 1rem;

   }


   .footer-item8{
    grid-row: 3;
    grid-column: 2/4;
    text-align: right;
   }
                                                          /* Contact start */
   .contact-form-main{
    margin-top: 100px;
}
#mob-contact-form-txt{
    display: none;
}
.contact-form-main>h2{
   text-align: center;
   color: var(--blue);
   font-size: 28px;
   font-family: 'Poppins',sans-serif;
   font-weight: var(--medium);
   margin-top: 2rem;
   margin-bottom: 2rem;
}
.contact-form-main p{
    text-align: center;
    margin-bottom: 2rem;
    font-family: 'Poppins',sans-serif;
    font-size: 20px;
    color: var(--bodyTextColor);
    
 }
 .contact-form-container{
    margin-left:auto;
    margin-right: auto;
 }
.contact-form{
    text-align: center;
    max-width: 761px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

}
.contact-form>div{
    max-width:761px;
    margin-bottom: 1.5rem;
    width:100%;
    
}
.contact-form>div>input,textarea{
    width: 100%;
    height: 74px;
    font-family: 'Poppins',sans-serif;
    font-size: 1rem;
    border-radius: 10px;
    padding-left: 1rem; 
    color: var(--bodyTextColor);
    border: none;
    box-shadow: inset 0px 0px 6px #00000020;
    /* -webkit-box-shadow:inset 0px 0px 6px #00000020; */
    /* -webkit-box-shadow: 10px 10px 5px #888; */
}
.contact-form>div>input:focus,
.contact-form>div>textarea:focus{
    outline: none !important;
    border: 2px solid var(--blue);
}
.contact-form textarea{
    height: 177px;
    padding-top: 1rem;
}
.contact-form-container>h2{
    color: var(--blue);
    text-align: center;
    margin-bottom: 2rem;
}
.contact-form-container>h2{
    margin-top: 2.5rem;
}
.contact-form-container>p{
    text-align: center;
}
.contact-form-btn-container a,
.contact-form-btn-container input{
  font-family: 'Quicksand',sans-serif;
  font-size: 1rem;
  background-color: var(--blue);
  border: none;
  color: white;
  display: inline-block;
}
.contact-form-btn-container>div{
    margin-top: 1.5rem;
}
.contact-form-btn-container>div input{
    width: 142px;
    height: 41px;
    border-radius: 8px;
}
.contact-form-btn-container a{
    padding: 20px 50px;
    border-radius: 30px;
    text-decoration: none;
  
}
/* contact end*/

/* Footer start */
.footer-container{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto auto;
    background-color: var(--footerBgColor);
    box-shadow: 0px -1px 10px #00000016;
    border-top: 1px solid white;
    border-radius: 15px;
    letter-spacing: .5px;
    max-width: 1286px;
    padding: 1.5rem 15px;
    margin: 0;
   
}
.footer-container a{
    text-decoration: none;
    color: var(--footerTextColor);
}


.footer-txt-1{
    margin-top: 20px;
    font-family:'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    margin-left: 5px;
    margin-right: 5px;
}
.footer-item1{
    margin-top: 30px;
}
.footer-item4{
    margin-bottom: 30px;
}
.footer-item5{
    margin-bottom: 30px;
    width: fit-content;
}
.footer-item6{
    grid-column: 3/6;
    display: flow-root;
    margin-bottom: 1rem;
}

.footer-item6 input[type="email"]{
    height:57px;
    border-radius:30px;
    min-width: 250px;
    border: none;
    margin-top: 10px;
    padding-left: 23px;
    font-family:'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    width: 62%;
    font-style: italic;
    /* background:url(submit.svg) no-repeat white right; */
    /* background-position-x: 99%; */
    
    
   }


.footer-item7{
    grid-column: 5;
}
.footer-item7>a{
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    font-family:'Poppins', sans-serif;
    color: var(--footerTextColor);
}
.footer-item8{
    font-family:'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
}
.footer-container.ul{
    list-style-type: none;
}
.footer-list{
    list-style-type: none;
}
.footer-container .footer-txt{
    color: var(--footerTextColor);
}
h4.footer-txt{
    font-family:'Poppins', sans-serif;
    font-size: 14px;
    margin-bottom: 23px;
    font-weight: 600;
}
li.footer-txt{
    font-family:'Poppins', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 200;
}

.footer-txt-2{
    font-weight:500;
    font-family:'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: italic
    }
.footer-txt-3{
    font-weight:500;
    }
.footer-txt-4{
    font-weight:600;
    }
 
#email-submit-icon{
   position: relative;
   right: 45px;
   top: 10px;
}
  
  
/* Footer end */

/* Loader start*/
#subs-txt-container{
    display: none;
}
#loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin: auto;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
/* Loader end */

/* Cookies start */
.pin-title {
    position: fixed;
    top: 1.875rem;
    bottom: 1.875rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Exo', sans-serif;
    font-weight: 800;
    font-size: 800%;
    letter-spacing: 0.0625rem;
    color: #f3f3f3;
    user-select: none;
    z-index: -1;
    text-align: center;
  }
  
  
  /* COOKIE POPUP STYLES */
  
  .cookie-popup {
    background: #fff;
    color: #000;
    font-size: 0.875rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: fixed;
    bottom: -6.25rem;
    left: 1.25rem;
    right: 1.25rem;
    padding: 0.625rem 0.9375rem;
    box-shadow: 0 0 0.625rem 0 rgba(0,0,0, .15);
    line-height: 150%;
    transition: opacity .5s;
    opacity: 0;
  }


  .cookie-popup--not-accepted {
    opacity: 1;
    animation: cookie-popup-in .5s ease forwards;  
  }
  .cookie-popup--accepted {
    opacity: 0;
  }
  .cookie-popup a {
    color: skyblue;
  }
  .cookie-popup a:visited {
    color: skyblue;
    text-decoration: none;
  }
  .cookie-popup-actions {
    flex: 1;
    text-align: right;
  }
  .cookie-popup-actions button {
    color: orange;
    border: none;
    font-family: inherit;
    font-style: inherit;
    font-size: inherit;
    font-weight: bold;
    font-size: 	0.75rem;
    padding: .5rem;
    margin: .5rem;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
    width: 100px;
  }
  .cookie-popup-actions #deny{
    color: white;
    background:var(--blue);
  }
  .cookie-popup-actions #accept{
    color:var(--blue);
    background:white;
    border: 1px solid var(--blue);
    
  }
  .cookie-popup-actions button:hover {
    text-decoration: underline;
  }
  
  @keyframes cookie-popup-in {
    from { bottom: -6.25rem; }
    to { bottom: 1.25rem; }
  }
/* Cookies end */