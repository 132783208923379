:root{
    --navBorderColor:#0000001e;
    --knockBgColor:#16d6ff;
    --textGreenColor:#00d28f;
    --taglineTextColor:#15CEFF;
    --taglineFullstopColor:#41D692;
    --landingStaticTextColor:#3E3E3E;
    --landingDynamicTextColor:#41D692;
    --headlineTextColor:#15CEFF;
    --bodyTextColor:#3E3E3E;
    --subheadlineTextColor:#15CEFF;
    --footerTextColor:#3E3E3E;
    --blue:#15CEFF;
    --max-nav-footer-width:1286px;
    --max-content-width:1130px;
    --footerBgColor:#f7f7f7;
    --footerBgColor:#f5f5f5;
    --BoxShadow:0 0 6px #51515120;
    --regular:400;
    --medium:500;
    --semiBold:600;
}
@media only screen and (max-width: 320px) {
  
    .project-btn-container{
       display: flex;
       justify-content:space-between;
       }
    .project-btn-container>div{
     width: 7rem;
    }
    .project-btn-container>div>button{
        padding: 1rem 0;
        margin-right: 1rem;
       }
    .tagline-txt{
        font-size: 1.3rem;
    }
}






@media only screen and (max-width: 600px) {
    .landing-dynamic-txt{
        font-size: 24px;
    }
    .services-icon{
        margin-left: auto;
        margin-right: auto ;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 860px) {

    #mob-tagline-txt h1{
        font-family: 'Roboto Slab',sans-serif;
        font-weight: bold;
        font-size:45px;
    }
 

   
    
   
    .txt-container{
        margin-top: 1.5rem;
    }
    .services-icons-container{
        width: auto;
    }
    #mob-body-txt{
        display: none;
        
    }
    .tab-desk-body-txt{
        line-height: 25px;
        font-family: 'Poppins',sans-serif;
    }
    .headline-txt{
        font-size: 28px;
        font-weight: var(--medium);
        margin-bottom: 2rem;
    }
   .services-icons-container{
    width: 90%;
   }
   .services-qutote{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
   }
 


   .sub-headline-txt{
    font-size: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
   }
   #desk-privacy-txt-container{
    display: block;
   }
   .privacy-container>h3{
    font-size: 16px;
    font-weight: var(--medium);
    text-align: left;
}

#tab-desk-privacy-txt-container{
    display: block;
}
#tab-desk-privacy-txt-container p{
    font-size: 14px;
    line-height: 25px;
}
                        
}

                                            /* Who We Are */
@media only screen and (min-width: 600px){
    .project-details-wrapper{
        width:90%;
        height: 128px;
        display: flex;
        border:none;
        box-shadow: var(--BoxShadow);
        padding: 16px 16px;
        border-radius: 8px;
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
    } 
  
    .whatwedo-container,.future-container{
        margin-top: 0 !important;
    } 
  
    
    #project-details-2{
        padding: 5px 20px;
        width:40% !important;
        height: 56px;
        margin-top: auto;
        margin-bottom: auto;
    } 
    #project-details-2 :first-child{
        margin-top: -15px;
    }
    .project-details{
        display: flex;
        flex-direction: column;
        height: fit-content;
    }
    .project-details :first-child{
        font-size: 59px;
    }
    .project-details :last-child{
        font-size: 14px;
        font-weight: var(--regular);
    }
    .project-btn-container{
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;
        margin-top: 2rem;
        width: 551px;
        margin-left: auto;
        margin-right: auto;
        
       }
    .project-btn-container>div{
               width: 229px;
               height: 41px;
          }
    .pro-service-btn,.pro-quoto-btn{
        font-size: 16px;
            
           }
    .pro-service-btn{
        padding: 10px 77px;
    }
    .pro-quoto-btn{
        padding: 10px 50px;
    }
}
@media only screen and (max-width: 860px){
    .whoweare-main .headline-txt{
        margin: 2rem;
    }
  

    .nav-bar-container{
        height: 8vh;
    }
   
    .privacy-container>h3{
        font-size: 16px;
    }
    .privacy-wrapper>p{
        font-size: 16px;
    }

    #tab-desk-privacy-txt-container{
        display: block;
    }
    .privacy-wrapper{
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
    /* Who we are start */
   
   
}
@media only screen and (min-width: 768px){
    .whoweare-main{
        width: 732px;
        margin-left: auto;
        margin-right: auto;
    }

    .whoweare-container>h2{
        font-size: 28px;
    }
}



@media only screen and (min-width: 601px) and (max-width: 860px) {
    /* Landing Page Start */
    .landing-page-container {
        margin-top: 100px;
        height: calc(100vh - 100px);
        
    }
    #tab-desk-tagline-txt .tagline-txt{
        font-size: 45px;
        font-weight:bold;
        color: var(--taglineTextColor);
        font-family: 'Roboto Slab', sans-serif;
        text-align: center;
    }

    .main-container{
        margin-top:0;
    }
    .main-text-container{
        margin-top:2rem;
    }
   
    .mob-landing-static-txt{
        display: none;
    }
    #tab-desk-landing-static-txt{
        display: block;
        text-align: center;
        font-size: 20px;
        font-weight: var(--medium);
        font-family: 'Poppins', sans-serif;
        color: var(--landingStaticTextColor);
        
    }
    #animation{
        display: block;
        width: 100%;
    }
    .console-container{
        font-size: 35px;
        font-weight: var(--medium);
        text-align: center;
        margin-top: 1rem;
    }
 
    .btn-container{
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1.5rem;
    }
    .btn-container>input {
        margin-right: 3.1rem;
    }
  
     /* Landing page end */
     .careers-container{
        padding: 0 2rem;
    }
       .careers-msg p{
        width: auto;
       }
       .careers-msg{
        padding: 2rem;
        margin:1rem auto;
       }
    #tab-project-svg{
        display: block;
       }
    .landing-services-container {
        margin-top: 0;
      }
      .process-container{
        margin-top: 2rem;
       }
    .tab-desk-body-txt {
        letter-spacing: 0;
    }
    #project-details-container p{
        font-size: 18px;
    }
    #tab-desk-privacy-txt-container p{
        font-size: 14px;
        line-height: 25px;
    }
}
@media only screen and (max-width: 860px) {
    /* Landing Page start */
    .nav-bar>ul,.knock-container{
        display: none; 
    }
    .main-container{
        display: block;
    }
    .main-text-wrapper{
        width: auto;
    }
    
                                                    /* Services start */
    .services-main-container{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .services-container{
           width: 100%; 
           column-gap: 2.5rem;
    }
      
    .tech-stack-container{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
 
 
    }
    @media only screen and (min-width: 601px) and (max-width: 860px) {
        .service{
            width: 100%;
            padding: 0 1rem 1rem 1rem;
        }
        .tech-stack-wrapper{
            width: 100%;
            grid-template-columns: 20vw 20vw 20vw 20vw;
            grid-template-rows: 20vw 20vw 20vw 20vw 20vw 20vw;
        }
        .tech-stack-wrapper>div{
            width: 100%;
            height: 100%;
            padding: 2rem;
        }
    }
   

        @media only screen and (min-width: 861px) {
            #tab-desk-tagline-txt .tagline-txt{
                letter-spacing: 2px;
            }
            .main-text-container{
                margin-left: 80px;
                margin-top: 9rem;
            }
            #landing-desk-tab-view{
                margin-top: 1.5rem;
            }
            #desk-project-svg{
                display: block;
            }
          
        }