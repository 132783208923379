:root{
    --navBorderColor:#0000001e;
    --knockBgColor:#16d6ff;
    --textGreenColor:#00d28f;
    --taglineTextColor:#15CEFF;
    --taglineFullstopColor:#41D692;
    --landingStaticTextColor:#3E3E3E;
    --landingDynamicTextColor:#41D692;
    --headlineTextColor:#15CEFF;
    --bodyTextColor:#3E3E3E;
    --subheadlineTextColor:#15CEFF;
    --footerTextColor:#3E3E3E;
    --blue:#15CEFF;
    --max-width:1286px;
    --footerBgColor:#f5f5f5;
    --BoxShadow:0 0 6px #51515120;
    --regular:400;
    --medium:500;
    --semiBold:600;
    --light:300;
}
@media only screen and (max-width: 600px){
    /* Our process Start */
 .body-txt-5{
     display: block;
    }
 .tab-desk-body-txt-5{
     display: none;
 }
    .our-process-mob-view{
     display: flex;
     overflow-y: scroll;
 }


 .process-slider {
     scroll-behavior: smooth;
     /* transition: transform 2s; */
     /* -ms-overflow-style: none;  
     scrollbar-width: none; */
       }
 .process-dash-line{
     margin-top: auto;
     margin-bottom: auto;
 }
 /* Our process end */
 .main-text-container{
     margin-top: 2rem;
 }
 .main-container{
     margin: 0;
 }
 .landing-page-container{
     height: calc(100vh - 100px);
     margin-top: 100px;
 }
 #mob-tagline-txt .tagline-txt{
    color: var(--taglineTextColor);
    font-size: 3.1rem;
    font-family: 'Roboto Slab', sans-serif;
    font-weight: bold;
    text-align: center;
    margin: 0;
    }
.txt-container {
    margin-top: 2rem;
    }
.console-container{
    font-size: 25px;
}
 #tab-desk-landing-static-txt{
     display: none;
 }
 .landing-services-container{
     margin-top: 0;
 }
 #tab-desk-tagline-txt{
     display: none;
 }
 #mob-tagline-txt{
     display: block;
 }
 .mob-landing-static-txt{
     display: block;
 }
 .console-container{
     text-align: center;
     margin-top: 1rem;
 }
 .btn-container>a{
    margin: 0;
 }
 .btn-container {
    gap: 1rem;
 }
 .headline-txt{
    font-size: 20px;
    margin-bottom: 1rem;
 }
 .tab-desk-body-txt{
    display: none;
 }
 #mob-body-txt{
    display: block;
    font-size: 16px;
 }
 .process-container>.headline-txt{
    margin: 0 0 40px 0;
 }
 .sub-headline-txt{
    font-size: 16px;
 }
 .process-container{
    padding: 40px 0px;
 }
 .process-des-container{
    width: 100%;
    height: 12rem;
}
 #animation{
     display: none;
 }
 .btn-container{
     flex-direction: column;
     margin-right: auto;
     margin-left: auto;
     margin-top: 2rem;
 }
 /* Project details start */
 #project-count-container>div span:first-child{
    font-size: 40px;
 }
 #project-count-container>div span:last-child{
    font-size: 14px;
}
#project-details-container p{
    font-size: 16px;
}
#project-btn-container{
    gap: 40px;
}
#project-btn-container>a{
    width: 148px;
}
#pro-quoto-btn{
    padding: 11px 0px;
}
 /* Services container */
 .services-icons-container{
     display: block;
 }
 .services-icons-container>a{
     margin-left: auto;
     margin-right: auto;
     margin-bottom: 2rem;
 }
 .careers-main-container{
    margin: 68px 2rem 0 2rem;
    padding-top: 2rem;
 }
 #tab-desk-careers-msg-txt{
    display: none;
 }
 #mob-careers-msg-txt{
    display: block;
    text-align: center;
 }
 .careers-container>h2{
     text-align: center;
     font-size: 20px;
     margin-top: 0;
 }
 .careers-container p{
     text-align: left;
     margin-bottom: 2rem;
     line-height: 25px;
 }
.careers-wrapper{
 flex-direction: column;
}

.careers-msg p{
 width: auto;
}
.careers-msg{
 padding:2rem 2rem;
}
#careers-icon{
    margin-top: 1.5rem;
}
.careers-wrapper>div:first-child{
    margin: auto;
}
#mob-project-svg{
 display: block;
}
.whoweare-wrapper, .whatwedo-wrapper, .future-wrapper {
 display: block;
 width: 100%;
}
.whoweare-wrapper>div, .whatwedo-wrapper>div, .future-wrapper>div {
 width: 100%;
}
}


@media only screen and (max-width: 600px) {
    .services-main-container{
        margin-left: auto;
        margin-right: auto;
    }
    .services-container{
           display: block;
           width:fit-content;
    }
    #services-mob-txt{
        display: block;
    }
    #services-tab-desk-txt{
        display: none;
    }
    .services-main-container>p{
        font-size: 16px;
    }
    .service{
            width:90%;
            height: 353px;
            padding: 1rem 1rem 1rem 1rem;
            margin: auto;
            margin-bottom: 2rem;
        }
    .service p{
        line-height: 25px;
    }
    .tech-stack-container{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .tech-stack-wrapper{
        width:76vw;
        grid-template-columns: 33vw 33vw;
        grid-template-rows:auto;
        grid-gap: 10vw;
        margin: auto;

      
        
    }
    .tech-stack-wrapper>div{
       width: 33vw;
       height: 33vw;
       padding: 2rem;
       
    }
    .tech-stack-container>div span{
        font-size: 10px;
    }
    .tech-stack-container p {
        font-size: 16px;
    }
                                                                /* Contact start */
.contact-form-main{
    margin-top: 68px;
    padding-top: 2rem;
}
.contact-form-main>h2{
    font-size: 20px;
    margin-top: 0;
}
.contact-form>div>input, textarea{
    width: 90%;
    -webkit-appearance: none;
    box-shadow: inset 0px 0px 6px #00000020;

}
.contact-form-main p{
    font-size: 16px;
}
#mob-contact-form-txt{
    display: block;
}
#tab-desk-contact-form-txt{
    display: none;
}
                    /* Privacy start */
#mob-privacy-txt-container{
    display: block;
}
#mob-privacy-txt-container>p{
  font-size: 12px;
}
#tab-desk-privacy-txt-container{
    display: none;
}
}