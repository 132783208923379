:root{
    --navBorderColorDesk:#eaeaea;
    --footerBgColor:#f5f5f5;
    --BoxShadow:0 0 6px #51515120;

}
@media only screen and (min-width: 378px) and (max-width: 480px) {
    .footer-item6 input[type="submit"]{
        right: 70px;
        margin-top: 22px;
    }
}
@media only screen and (max-width: 375px) {
    .popup-navbar-content>ul{
        margin-top: 50%;
    }
    .popup-social-footer{
        bottom: 2%;
    }
    .footer-item1 p{
        width: max-content;
    }
    .footer-item6 input[type="submit"]{
        margin-top: 24px;
    }
   
}
@media only screen and (max-width: 391px) {
    .footer-item6 input[type="email"]{
        width: 250px;
    }
}
@media only screen and (max-width: 310px) {
    .footer-item6 input[type="submit"]{
        right: 5%;
    }
}
@media only screen and (max-width: 860px) {
    .hamburger-container{
        display: block;
    }
}

@media only screen and (max-width: 600px) {
   
    .process-container{
        margin-left: auto;
        margin-right: auto;
    }
    .process-wrapper{
        margin-left: 0;
        margin-right: 0;
    }
  
    /*footer*/
   .footer-container{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto auto auto auto;
   }
   .footer-item2, .footer-item3, .footer-item4, .footer-item5 {
    margin: 0;
}
   .footer-item1{
    grid-row: 5;
    grid-column: 1/3;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
   }
   .footer-item2{
    text-align: center;
   }
   
   .footer-item3{
    grid-column: 2;
    text-align: center;

   }
   .footer-item4{
    grid-row: 3;
    grid-column: 1/3;
    text-align: center;
    margin-top: 23px;
    margin-bottom: 23px;
    margin: 23px auto;

   }
   .footer-item5{
    grid-row: 2;
    grid-column: 1/3;
    text-align: center;
    width: auto;

   }
   .footer-item6{
    grid-row: 4;
    grid-column: 1/3;
    text-align: center;
    margin: 0;

   }
 
   .footer-item7{
    grid-row: 6;
    grid-column: 1/3;
    text-align: center;
    margin: 23px auto;
}
.footer-item7>span{
    display: block;
}
.footer-item8{
    grid-row: 7;
    grid-column: 1/3;
    text-align: center;
}
.footer-item8>span{
    display: block;
}
.our-process-tab-view{
    display: none;
}
.footer-item6 input[type="email"]{
margin-left: 0;
padding-right: 50px;
}
}

@media only screen and (max-width: 860px) {
    .popup-navbar-content>ul{
        display: block;
        text-align: center;
    }
    .popup-navbar-content>ul>li{
        display: block;
        text-align: center;
        margin-right: 0;
    }
    .popup-navbar-content>.knock{
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3rem;
    }
   
    .hamburger{
        display: block;
    }
    .main-text-container{
        /* margin-left: 0; */
        
    }
   
  
    .landing-dynamic-txt{
        text-align: center;
        margin-top: 0;
    }
 
   

    .main-text-container{
        /* position: relative;
        top: 0; */
    }
    .social-footer{
        display: none;
    }
   
    .our-process-tap-view{
        display: none;
    }
    .our-process-desk-view{
        display: none;
    }
    }

@media only screen and (min-width:601px) and (max-width: 860px) {
    .popup-navbar-content ul{
        margin-top: 65%;
    }
    .process-container{
        margin-left: auto;
        margin-right: auto;
    }
    .our-process-mob-view{
        display: none;
    }
    .our-process-tab-view{
        display: block;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
   
    .process-des-container{
        width: 100%;
    }
    .our-process-mob-view{
        display: none;
    }
    .our-process-tap-view{
        text-align: center;
    }

 
    /*footer*/
   .footer-container{
    display: grid;
    grid-template-columns: 25% 25% 20% 30%;
    grid-template-rows: auto auto auto;
    padding:0 15px;
   }

 
   .footer-item1{
    grid-row: 2/4;
    grid-column: 1;
    width: 21rem;
    margin: 0;
    

   }
   .footer-item2{
    grid-row: 1;
    grid-column: 1;
    margin-top: 1.5rem;
   }
   
   .footer-item3{
    grid-row: 1;
    grid-column: 2;
    margin-top: 1.5rem;
    
   }
   .footer-item4{
    grid-row: 1;
    grid-column: 3;
    margin-top: 1.5rem;

   }
   .footer-item5{
    grid-row: 1;
    grid-column:4;
    margin-top: 1.5rem;

   }
   .footer-item6{
    grid-row: 2;
    grid-column: 2/5;
    text-align: center;
    margin: 0;

   }
   .footer-item6 input[type="submit"]{
    /* margin: 0; */

   }
   .footer-item6 input[type="email"]{
    height:3rem;
    border-radius: 2rem;
    width: 58%;
    border: none;
    margin-top: .5rem;
    padding-left: 1rem;
    margin-left: 1rem;
   }
   .footer-item7{
    grid-row: 3;
    grid-column: 3/5;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}
.footer-item8{
    grid-row: 3;
    grid-column: 2/4;
    margin-top: auto;
    margin-bottom: -1rem;
    text-align: end;
}
.footer-item7>span{
    display: block;
}
.footer-item8>span{
    display: block;
}
}
@media only screen and (min-width: 861px) {
     /* .nav-bar>ul,.knock-container{
        display: block; 
    }
    .nav-bar{
        padding-top: .2rem;
        height: 68px;
        padding: 6px 15px;
        box-shadow: 0 3px 5px #00000016;   
    }
    .hamburger-container{
        display: none;
    }
    .our-process-mob-view{
        display: none;
    }
    .our-process-tab-view{
        display: none;
    }
    .our-process-desk-view{
        display: block;
    }
 
    .our-process-desk-view{
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
    }
    .our-process-desk-view>svg{
        margin-left: auto;
        margin-right: auto;
    }
    .process-container{
        margin-left: auto;
        margin-right: auto;
    }
    .footer-item1{
        grid-row: 1/3;
        margin-top: 1.5rem;
        margin-left: 3rem;
    }
   
    .footer-item1 p{
        margin-top: 1.5rem;
        width: max-content;
    }
     .footer-item2,.footer-item3,.footer-item4,.footer-item5{
        margin-top: 1.5rem;
     }
    .footer-item6{
        grid-column: 3/6;
        margin: 2rem;
    }
    .footer-container{
        padding: 1.5rem 2rem;
        margin: 0;
    }
      
    .footer-item6 input[type="email"]{
        width: 65%;
        max-width: initial;
        margin-left: 1rem;

       }
       .footer-item6 input[type="submit"]{
        right: 12%; 
       }
       .footer-item7{
        grid-column: 4/6;
        text-align: right;
        margin-right: 6rem;
       }
       .footer-item8{
        grid-row: 3;
        grid-column: 2/4;
        text-align: right;
       }  */

}

@media only screen and (min-width: 1287px) {
     .nav-bar{
        box-shadow: none;
        /* width: 1286px;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto; */
        
    } 
    #nav-bar-container{
        width: 100%;
        top: 0;
        position: fixed;
        box-shadow: 0 3px 5px #00000016;
        background-color: white;
        z-index: 2;
    }
    .footer-container{
        margin-left: auto;
        margin-right: auto;
        border-radius: 0;
        box-shadow: none;
        border: none;
    }
    #footer-bar-container{
        width: 100%;
        background-color: var(--footerBgColor);
        box-shadow: var(--BoxShadow);
    }
 
}