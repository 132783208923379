:root{
  --blue:#15CEFF;
  --landingDynamicTextColor:#41D692;
}


/* text animation start*/
.hidden {
  opacity:0;
}

.console-underscore {
  display:inline-block;
  color: var(--landingDynamicTextColor);
  margin-top: 1rem;
  font-family:'Roboto Slab', sans-serif;
  font-size: 35px;
  font-weight: var(--medium);
  letter-spacing: 2px;
 
}
/* text animation end */



@keyframes intro{
 0%{
    opacity: 1;
 }
 25%{
  opacity: 1;
 }
 75%{
  opacity: 0;
 }
 100%{
  opacity: 0;
 }
}

#mob-anim-tagline-txt-container,#tab-desk-anim-tagline-txt-container{
    overflow: hidden;
}


.lineup1 {
    animation: 2s anim-tagline-up1 .5s ease-out;
    animation-fill-mode: forwards;
    
  }
.lineup2 {
    animation: 2s anim-tagline-up2 1s ease-out;
    animation-fill-mode: forwards;
  }
  .lineup3 {
    animation: 2s anim-tagline-up3 1.5s ease-out;
    animation-fill-mode: forwards;
  }

  @keyframes anim-tagline-up1{
    0% {
      opacity: 0;
      transform: translateY(282%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @keyframes anim-tagline-up2{
    0% {
      opacity: 0;
      transform: translateY(181%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @keyframes anim-tagline-up3{
    0% {
      opacity: 0;
      transform: translateY(82%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .mob-landing-static-txt,#tab-desk-landing-static-txt{
    animation: 5s fadein 0s ease-out;
    
  }
  @keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
    
}


/* our method start */

/* fro desk viwe */

#Design_Development_,#Testing_Deployment_, #Maintenance_Support_{
  opacity: 0.5;
}
#desk-Dot-3,#desk-Dot-4,#desk-Dot-5{
  opacity: 0.2;
}

#desk_Ellipse_119,#desk_Ellipse_120,#desk_Ellipse_121{
  opacity: 0.2;
}

#desk_Dotted_Line,#desk_Dotted_Line-2,#desk_Dotted_Line-3{
  opacity: 0.2;
}


/* for tab view */
#tab-Group_390,#tab-Group_391,#tab-Group_392{
opacity: 0.2;
}
#tab_Ellipse_121,#tab_Ellipse_128,#tab_Ellipse_127{
  opacity: 0.2;
}
#tab-Dot-2,#tab-Dot-3,#tab-Dot-4{
opacity: 0.2;
}
/* 
#Maintenance_Support:hover{
  animation: 3s our-method;
}
  @keyframes our-method{
    form{
  transform: translateX(60px);
    }
    to{
      transform: translateX(0px);
    }
}  */
/* our method end */
